/**
 * @deprecated
 */
export const NAV_TEMPLATE_HUB = "/s/template-hub";
export const NAV_MARKETS = "/s/markets";
export const NAV_MARKET = "/s/market";
/**
 * @deprecated
 */
export const NAV_MARKET_PROFILE = "/s/market-profile";
/**
 * @deprecated
 */
export const NAV_TEMPLATE_PREVIEW = "/s/template-hub#/template-preview";
