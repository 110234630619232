import { FreeTrialRole, postAsyncRequest, useUserState } from "gx-npm-lib";
import {
  ButtonLoader,
  FreeTrialInitCreateModalComponent,
  SnackbarBanner,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { reportFullStoryTemplateCreateEvent } from "../../../../lib/full-story-events.lib";
import { TemplateDetails } from "../../../../types";

type StartEvaluationProps = {
  templateDetails: TemplateDetails;
  templateId: number;
  templateName: string;
  isWithinScrollHeader?: boolean;
  userInitCount?: number;
};
const StartEvaluation: FC<StartEvaluationProps> = ({
  isWithinScrollHeader = false,
  templateDetails,
  templateId = 0,
  templateName = "",
  userInitCount = 0,
}) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { freeTrialRole, isEntitled } = useUserState();
  const isTrialUser = freeTrialRole === FreeTrialRole.FREE_TRIAL_RECIPIENT;

  const handleClickCloseFreeTrialModal = () => {
    setIsFreeTrialModalOpen(false);
  };

  const handleClickConfirmFreeTrialModal = async () => {
    setIsFreeTrialModalOpen(false);
    await createInitiative();
  };

  const handleClickStartEvaluation = async () => {
    if (isTrialUser) {
      setIsFreeTrialModalOpen(true);
    } else {
      await createInitiative();
    }
  };

  const createInitiative = async () => {
    setIsProcessing(true);
    const url = `api/v3/initiatives/`;
    const weightedPriorities = JSON.parse(sessionStorage.getItem(`weightedPriorities-${templateId}`) || "[]");
    const payload = { templateId, weightedPriorities };
    const response = await postAsyncRequest(url, payload);
    if (response?.status === 201 && response.data?.data?.initiativeId) {
      localStorage.setItem("showRibbonAnimation", "true");
      sessionStorage.removeItem(`weightedPriorities-${templateId}`);
      const config = {
        categoryId: templateDetails.categoryId,
        categoryName: templateDetails.category,
        isSearchData: templateDetails.isSearchData,
        searchTerm: templateDetails.searchTerm,
        searchResultsCount: templateDetails.searchResultsCount,
        templateId,
        templateName,
      };
      reportFullStoryTemplateCreateEvent(config);
      singleSpa.navigateToUrl(`/s/evaluation/${response.data.data.initiativeId}/overview`);
    } else {
      setIsError(true);
    }
    setIsProcessing(false);
  };

  const hasTrialAccess = isTrialUser && userInitCount < 3;
  return (
    <Fragment>
      <TooltipV2
        deactivate={isEntitled || hasTrialAccess}
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: isWithinScrollHeader ? "0px, 8px" : "0px, -8px" } } }}
        title={t(
          "Your account does not have permission to create initiatives. Please contact your Gartner Account Manager for assistance."
        )}
      >
        <div>
          <ButtonLoader
            btnClass={isWithinScrollHeader ? "btn-secondary" : "secondary-dark-theme-btn"}
            btnRootClassName="gx-start-eval-from-market-btn"
            disabled={!isEntitled && !hasTrialAccess}
            isLoading={isProcessing}
            onClick={handleClickStartEvaluation}
          >
            <TypographyComponent color={isWithinScrollHeader ? "defaultCta" : "white"} boldness={"semi"}>
              {t("START EVALUATION")}
            </TypographyComponent>
          </ButtonLoader>
        </div>
      </TooltipV2>
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isError} setIsOpen={() => setIsError(false)} type="ERROR" />
      <FreeTrialInitCreateModalComponent
        currentInits={userInitCount}
        isOpen={isFreeTrialModalOpen}
        isProcessing={isProcessing}
        onClickClose={handleClickCloseFreeTrialModal}
        onClickConfirm={handleClickConfirmFreeTrialModal}
      />
    </Fragment>
  );
};

export default StartEvaluation;
