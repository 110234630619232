import * as queryString from "query-string";
import * as singleSpa from "single-spa";

const defaultRoute = "/s/evaluations";

/**
 *
 * @description :: Will expect the following:
 * 1. s/template-preview-product?productId=:productId&templateId=:templateId
 * 2. s/template-preview-product/template-preview?productId=:productId&templateId=:templateId
 */
const HandleRedirects = () => {
  const { search, pathname } = window.location;
  const isTemplatePreviewPath = pathname.includes("template-preview-product/template-preview");
  const { templateId, productId } = queryString.parse(search);
  singleSpa.navigateToUrl(
    productId && templateId
      ? `/s/market/${templateId}/product/${productId}${isTemplatePreviewPath ? "/preview" : ""}${search}`
      : defaultRoute
  );
  return null;
};
export default HandleRedirects;
