// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pj4NQWL64g3sm0XFgcnb{margin-bottom:12px}.gWQhXe1QFRAOofYizZhC .tb4FNOKTXdqOvMZ0fD7W{padding-bottom:40px;padding-inline-start:24px}.jqYF3b47PjZXcyjW9UT_{float:right}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-info-modal/free-trial-info-modal.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,4CACE,mBAAA,CACA,yBAAA,CAGF,sBACE,WAAA","sourcesContent":[".sentenceBottomBreak {\n  margin-bottom: 12px;\n}\n\n.listContainer .list {\n  padding-bottom: 40px;\n  padding-inline-start: 24px;\n}\n\n.footerButton {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sentenceBottomBreak": `Pj4NQWL64g3sm0XFgcnb`,
	"listContainer": `gWQhXe1QFRAOofYizZhC`,
	"list": `tb4FNOKTXdqOvMZ0fD7W`,
	"footerButton": `jqYF3b47PjZXcyjW9UT_`
};
export default ___CSS_LOADER_EXPORT___;
