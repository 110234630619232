import { Fade } from "@mui/material";
import classNames from "classnames";
import { PreviewIcon } from "gx-npm-icons";
import { getAsyncRequest, isValidResponse, useCaptureEventsV2, useUserState, UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { FreeTrialBannerComponent, Loader, MiniButton, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { ClientEvent } from "../../app.constants";
import DefaultPreviewIcon from "../../assets/images/default-blue-preview.svg";
import { PreviewProductPayload, TemplateDetails } from "../../types";
import ProductPreviewAnalyticTrackerComponent from "./components/analytic-tracker/product-preview-analytic-tracker.component";
import NavigationLinksV2 from "./components/navigation-links/navigation-links-v2.component";
import StartEvaluation from "./components/start-evaluation/start-evaluation.component";
import ActiveEvalNotification from "./active-eval-notification/active-eval-notification.component";
import styles from "./preview-header-v2.styles.module.scss";

type PreviewHeaderProps = { productId: number; templateDetails: TemplateDetails; templateId: number };
const PreviewHeaderV2: FC<PreviewHeaderProps> = ({ productId = 0, templateDetails, templateId = 0 }) => {
  const { t } = useTranslation();
  const [productName, setProductName] = useState("");
  const [productLogo, setProductLogo] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [productCount, setProductCount] = useState(0);
  const [requirementCount, setRequirementCount] = useState(0);
  const [checklistCount, setChecklistCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [latestExistingInitId, setLatestExistingInitId] = useState<UUID>("");
  const [userInitCount, setUserInitCount] = useState(0);
  const { freeTrialDaysRemaining, freeTrialRole, isEntitled } = useUserState();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!templateId || !productId) {
      return;
    }
    (async () => {
      setIsLoading(true);
      const url = `api/v3/data/template/preview/${templateId}/product/${productId}`;
      const response = await getAsyncRequest(url);
      if (isValidResponse(response)) {
        const payload: PreviewProductPayload = response.data?.data;
        setProductName(payload.productName);
        setTemplateName(payload.templateName);
        setProductLogo(payload.imageLoc);
        setProductCount(payload.productCount);
        setRequirementCount(payload.requirementCount);
        setChecklistCount(payload.checklistCount);
        setLatestExistingInitId(payload.templateRecentInitId || "");
        setUserInitCount(payload.userInitCount || 0);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, [productId, templateId]);

  useEffect(() => {
    const bigHeaderHeight = 108;
    const handleScroll = () => {
      setIsScrolling(window.scrollY > bigHeaderHeight);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setIsScrolling]);

  useEffect(() => {
    if (templateId === 0 || productId === 0 || productName === "") {
      return;
    }

    const metaData = { productId: productId.toString(), productName, templateId: templateId.toString() };
    captureEvents([{ eventType: ClientEvent.MARKET_PRODUCT_PROFILE_PAGE_VIEWED, metaData }]);
  }, [captureEvents, productId, productName, templateId]);

  const handleClickPreview = () => {
    updateStateSubject("TEMPLATE_PREVIEW", {
      canUseTemplate: isEntitled,
      category: templateDetails.category || "",
      categoryId: templateDetails.categoryId || 0,
      display: true,
      isSearchData: templateDetails.isSearchData || false,
      searchResultsCount: templateDetails.searchResultsCount || 0,
      searchTerm: templateDetails.searchTerm || "",
      templateId,
      templateName,
      userInitCount,
    });
    singleSpa.navigateToUrl(`/s/market/${templateId}/product/${productId}/preview`);
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      {!isLoading && (
        <Fragment>
          {freeTrialRole && (
            <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />
          )}
          <div className={styles.container}>
            {!isScrolling && (
              <header className={classNames(styles.headerBg)}>
                <ActiveEvalNotification latestExistingInitId={latestExistingInitId} />
                <div className={classNames(styles.header)}>
                  <div>
                    <TypographyComponent boldness={"semi"} color={"white"} styling={"p1"}>
                      {` `}
                      <TypographyComponent boldness={"semi"} color={"white"} element={"span"}>
                        {productName}
                      </TypographyComponent>{" "}
                      {t("evaluation template")}
                    </TypographyComponent>
                    <ul className={styles.unOrderedList}>
                      <li className={styles.item}>
                        <TypographyComponent color="silver" boldness="semi" styling="p4">
                          <TypographyComponent
                            boldness="semi"
                            color="white"
                            rootClassName={styles.itemSpan}
                            styling="span"
                          >
                            {productCount}
                          </TypographyComponent>
                          {` `}
                          {t("Vendors")}
                        </TypographyComponent>
                      </li>
                      <li className={styles.item}>
                        <TypographyComponent boldness="semi" color="silver" styling="p4">
                          <TypographyComponent
                            boldness="semi"
                            color="white"
                            rootClassName={styles.itemSpan}
                            styling="span"
                          >
                            {requirementCount}
                          </TypographyComponent>
                          {` `}
                          {t("Requirements")}
                        </TypographyComponent>
                      </li>
                      <li className={classNames(styles.lastItem, styles.item)}>
                        <TypographyComponent color="silver" boldness="semi" styling="p4">
                          <TypographyComponent
                            boldness="semi"
                            color="white"
                            rootClassName={styles.itemSpan}
                            styling="span"
                          >
                            {checklistCount}
                          </TypographyComponent>
                          {` `}
                          {t("Checklist tasks")}
                        </TypographyComponent>
                      </li>
                    </ul>
                  </div>
                  <div className={classNames(styles.actions)}>
                    <MiniButton
                      onClick={handleClickPreview}
                      rootClassName={classNames("gx-preview-btn")}
                      variant="darkTheme"
                    >
                      <PreviewIcon /> {t("Preview Template")}
                    </MiniButton>
                    <StartEvaluation
                      templateDetails={templateDetails}
                      templateId={templateId}
                      templateName={templateName}
                      userInitCount={userInitCount}
                    />
                  </div>
                </div>
              </header>
            )}
          </div>
          {isScrolling && (
            <Fade timeout={{ enter: 500, exit: 500 }} in={isScrolling}>
              <header className={classNames(styles.scrolledHeaderBg)}>
                <ActiveEvalNotification isScrolling={isScrolling} latestExistingInitId={latestExistingInitId} />
                <div className={styles.mainNavOffSet}>
                  <div className={classNames(styles.scrolledHeader)}>
                    <NavigationLinksV2 templateId={templateId} templateName={templateName} productName={productName} />
                    <div className={classNames(styles.scrolledActions)}>
                      <MiniButton onClick={handleClickPreview} rootClassName="gx-preview-btn">
                        <img src={DefaultPreviewIcon} alt="" /> {t("Preview Template")}
                      </MiniButton>
                      <StartEvaluation
                        isWithinScrollHeader={true}
                        templateDetails={templateDetails}
                        templateId={templateId}
                        templateName={templateName}
                        userInitCount={userInitCount}
                      />
                    </div>
                  </div>
                </div>
              </header>
            </Fade>
          )}
          <div className={classNames(latestExistingInitId && styles.spacer, styles.navAndTitleWrapper)}>
            <NavigationLinksV2 templateId={templateId} templateName={templateName} productName={productName} />
            <div className={styles.imageAndTitleWrapper}>
              <div className={styles.imgBackground}>
                <img alt={productLogo} className="alternateVendorImage" src={productLogo} />
              </div>
              <TypographyComponent boldness="semi" styling="h3">
                {productName}
              </TypographyComponent>
            </div>
          </div>
        </Fragment>
      )}
      <ProductPreviewAnalyticTrackerComponent
        productId={productId}
        productName={productName}
        templateId={templateId}
        templateName={templateName}
      />
      <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
    </Fragment>
  );
};

export default PreviewHeaderV2;
