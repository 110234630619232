import React, { Fragment, useEffect } from "react";
import { useUserState } from "gx-npm-lib";

type ProductPreviewAnalyticTrackerProps = {
  templateId: number;
  productId: number;
  productName: string;
  templateName: string;
};
const ProductPreviewAnalyticTrackerComponent: React.FC<ProductPreviewAnalyticTrackerProps> = ({
  templateId,
  productId,
  productName,
  templateName,
}) => {
  const [lastSentProductName, setLastSentProductName] = React.useState<string>("");
  const { hashedUserId, isEntitled } = useUserState();

  useEffect(() => {
    if (!window.FS) {
      return;
    }
    if (!hashedUserId || !productName || !templateName) {
      return;
    }
    if (lastSentProductName === productName) {
      return;
    }
    setLastSentProductName(productName);
    const payload = {
      hashedUserId,
      isEntitled,
      marketId: templateId,
      marketName: templateName,
      productId,
      productName,
    };
    window.FS.event("view product profile event", payload);
  }, [
    hashedUserId,
    isEntitled,
    lastSentProductName,
    productId,
    productName,
    setLastSentProductName,
    templateId,
    templateName,
  ]);

  return <Fragment />;
};
export default ProductPreviewAnalyticTrackerComponent;
