import { createStateSubscription, destroyStateSubscription } from "gx-npm-messenger-util";
import { FeatureFlagsAvailableContainer } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import PreviewHeaderV2 from "./sections/preview-header/preview-header-v2.component";
import { TemplateDetails } from "./types";
import styles from "./app.styles.module.scss";

const stateNameTemplate = "PRODUCT_PREVIEW_FULLSTORY";
const App = () => {
  const location = useLocation();
  const [templateDetails, setTemplateDetails] = useState<TemplateDetails>({
    category: "",
    categoryId: 0,
    isSearchData: false,
    searchResultsCount: 0,
    searchTerm: "",
  });
  const { productId = "", templateId = "" } = useParams();

  useEffect(() => {
    const subscriptionTemplate = createStateSubscription(stateNameTemplate, handleStateUpdate);
    return () => {
      destroyStateSubscription(subscriptionTemplate);
    };
  }, [location, templateId, productId]);

  const handleStateUpdate = (updatedState: TemplateDetails) => {
    if (updatedState) {
      setTemplateDetails(updatedState);
    }
  };
  return (
    <section className={styles.container}>
      <FeatureFlagsAvailableContainer>
        <PreviewHeaderV2
          templateId={parseInt(templateId) || 0}
          productId={parseInt(productId) || 0}
          templateDetails={templateDetails}
        />
      </FeatureFlagsAvailableContainer>
    </section>
  );
};

export default App;
