import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HandleRedirects from "./handle-redirects";
import App from "./app.component";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <BrowserRouter>
        <Routes>
          <Route path={"/s/market/:templateId/product/:productId/template-preview"} element={<App />} />
          <Route path={"/s/market/:templateId/product/:productId"} element={<App />} />
          <Route path={"/s/market/:templateId/product/:productId/preview"} element={<App />} />
          <Route path={"*"} element={<HandleRedirects />} />
        </Routes>
      </BrowserRouter>
    </FeatureFlagProvider>
  );
};

export default Root;
