// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m5Cik10v_2owOBdlp_MV{display:flex;gap:7px;align-items:center}.JIbaOAHCPeD20l3tsRKZ{margin-top:1px}.m5Cik10v_2owOBdlp_MV svg{margin-bottom:-4px}.m5Cik10v_2owOBdlp_MV .XEG_lwDEs480S3iVoy3v{margin-bottom:-4px}`, "",{"version":3,"sources":["webpack://./src/sections/preview-header/components/navigation-links/navigation-links-v2.styles.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CAEF,0BACE,kBAAA,CAEF,4CACE,kBAAA","sourcesContent":["\n.container {\n  display: flex;\n  gap: 7px;\n  align-items: center;\n}\n.productName {\n  margin-top: 1px;\n}\n.container svg {\n  margin-bottom:-4px;\n}\n.container .gx-p {\n  margin-bottom:-4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `m5Cik10v_2owOBdlp_MV`,
	"productName": `JIbaOAHCPeD20l3tsRKZ`,
	"gx-p": `XEG_lwDEs480S3iVoy3v`
};
export default ___CSS_LOADER_EXPORT___;
